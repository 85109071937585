<template>
  <div id="login" class="d-flex fill-height">
    <v-main class="align-center">
      <v-container fluid>
        <v-card max-width="400" class="mx-auto">
          <v-card-title v-text="systemTitle"></v-card-title>
          <v-card-text>
            <v-form ref="form" v-model="valid">
              <v-text-field
                v-model="data.account"
                :rules="accountRules"
                label="账号"
                required
                :error-messages="accountErrorMessage"
              ></v-text-field>

              <v-text-field
                v-model="data.password"
                :rules="passwordRules"
                type="password"
                label="密码"
                required
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions class="d-flex justify-end">
            <v-btn color="primary" @click="login" block> 登录 </v-btn>
          </v-card-actions>
        </v-card>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
export default {
  computed: {
    ...mapState(["systemTitle"]),
  },
  methods: {
    async login() {
      if (!this.$refs.form.validate()) return;
      this.$shareyueApi
        .login(this.data)
        .then(() => {
          this.$router.replace("/index");
        })
        .catch((err) => {
          console.error(err);
          this.accountErrorMessage = err;
        });
    },
  },
  data() {
    return {
      data: {
        account: "",
        password: "",
      },
      valid: true,
      accountErrorMessage: "",
      accountRules: [(v) => !!v || "请填写账号"],
      passwordRules: [(v) => !!v || "请填写密码"],
    };
  },
};
</script>
